import { Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { FormGroup, FormBuilder, FormsModule } from '@angular/forms';
import { GetInfosResponse } from 'src/app/shared/services/API/responses/autoparts/Vehicle/get-infos.response';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalChangeVehicleComponent } from '../search/search-header/modal-change-vehicle/modal-change-vehicle.component';
import { PartsDetailsService } from 'src/app/shared/services/API/services/autoparts/parts-details.service';
import { PartsDetailsRequest } from 'src/app/shared/services/API/requests/autoparts/parts-details.request';
import { PartsDetailsResponse } from 'src/app/shared/services/API/responses/autoparts/parts-details.response';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PartProductStruct } from 'src/app/shared/services/API/structs/autoparts/part-product.struct';
import { SearchShoppingCartStruct, SearchShoppingCartService } from '../search/search-shopping-cart/search-shopping-cart.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private partsDetailsService: PartsDetailsService,
    private router: Router,
    private route: ActivatedRoute,
    private searchShoppingCartService:SearchShoppingCartService,
    private alertService: AlertService,
    private location: Location,
  ){
  }
  
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public FormsModule: FormsModule;
  public setAplication: boolean = true;
  public setSpecification: boolean = false;
  public setSimilares: boolean = false;
  public partsDetailsRequest: PartsDetailsRequest;
  public partsDetailsResponse: PartsDetailsResponse;
  public isLoading: boolean = false;
  public partId: number;
  public supplierBusinessId: number;
  public aplication: any;
  public model: FormGroup;
  public quantity: number;
  public product: any;
  public imagens: string[] = [];
  public searchShoppingCartStuct:SearchShoppingCartStruct = null;
  public hasItemsInCart: boolean = false;
  public specs: any;
  public specsFiscal: any;
  public specsLogistico: any;
  public specsTecnico: any;
  public applicability: any;
  public similars: any;
  public salesMultiple: number;

  @Output() addItem = new EventEmitter<number>();
  
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.partId = params['partId'];
      this.supplierBusinessId = params['supplierBusinessId']
  });

  this.PopulatePage()
}

backPage(){
  this.location.back();
}

PopulatePage(){
  const partsDetailsRequest: PartsDetailsRequest = new PartsDetailsRequest();
  partsDetailsRequest.partId = Number(this.partId);
  partsDetailsRequest.supplierBusinessId = Number(this.supplierBusinessId);
  
  if(this.partsDetailsService){
    this.partsDetailsService.post(partsDetailsRequest).subscribe({
        next: (partsDetailsResponse: PartsDetailsResponse) => {
          if(partsDetailsResponse.isError){
            this.alertService.show('Erro', partsDetailsResponse.errorDescription, AlertType.error)
            this.isLoading = false;
            return;
          }
          this.partsDetailsResponse = partsDetailsResponse;
          this.product = this.partsDetailsResponse;
          this.product = this.product.partProductDetails

          this.quantity = this.product.totalQuantity;
          this.salesMultiple = this.product.salesMultiple;

          this.imagens = this.product.partImage64List;
          this.specs = this.product.specsStructList;
          this.specsFiscal = this.specs.filter(x => x.categoryName == 'Fiscal')
          this.specsLogistico = this.specs.filter(x => x.categoryName == 'Logístico')
          this.specsTecnico = this.specs.filter(x => x.categoryName == 'Técnico')

          this.applicability = this.product.applicabilityList;
          this.applicability = this.applicability
          .sort((a, b) => a.brandName.localeCompare(b.brandName));

          this.similars = this.product.similarsList
          this.similars = this.similars.sort((a, b) => a.brandName.localeCompare(b.brandName))

          this.model = this.formBuilder.group({
            amount: this.salesMultiple,
          });
        },
        error: (error) => {
          this.isLoading = false;
          this.alertService.show("Erro inesperado", error, AlertType.error);
        }
      });
    }
  }

  clickAddItem(){
    const amount = this.model.get("amount").value;
    const partProductStruct = this.product
    this.searchShoppingCartStuct = this.searchShoppingCartService.addPart(amount, partProductStruct);
    if(this.searchShoppingCartStuct){
      this.alertService.show('Sucesso', 'Item adicionado ao carrinho.', AlertType.success);
    }
  }

  generateAmountOptions(): number[] {
    return Array.from(
      { length: Math.ceil(this.quantity / this.salesMultiple) }, 
      (_, i) => (i + 1) * this.salesMultiple
    );
  }

  setIsAplication(){
    this.setAplication = true;
    this.setSpecification = false;
    this.setSimilares = false;
  }

  setIsSpecification(){
    this.setSpecification = true;
    this.setAplication = false;
    this.setSimilares = false;
  }

  setIsSimilares(){
    this.setSpecification = false;
    this.setAplication = false;
    this.setSimilares = true;
  }

  // @Output() OutputClickFilter = new EventEmitter<any>();
  // @Output() SearchClick = new EventEmitter<any>();

  // @Input() idServiceOrder:number;
  
  // @Input() modelSearch:FormGroup; 
  // @Input() getInfosResponse:GetInfosResponse;
  // @Input() year:GetInfosResponse;
  // @Input() plateNumber:GetInfosResponse;
  
  // clickFilter(){
  //   this.OutputClickFilter.emit();
  // }

  // seach(){
  //   this.SearchClick.emit();
  // }

  openModalChengeVehicle(){
    const dialogRef = this.dialog.open(ModalChangeVehicleComponent, {
      data: {
       
      },
    })
    dialogRef.afterClosed().subscribe({
      next: result => {
        
      }
    });
  }

}