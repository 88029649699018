import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalChangeVehicleComponent } from './modal-change-vehicle/modal-change-vehicle.component';
import { GetInfosResponse } from 'src/app/shared/services/API/responses/autoparts/Vehicle/get-infos.response';

@Component({
  selector: 'app-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.css']
})
export class SearchHeaderComponent implements OnInit {

  ngOnInit(): void {

  }

  constructor(public dialog: MatDialog ){

  }

  @Output() OutputClickFilter = new EventEmitter<any>();
  @Output() SearchClick = new EventEmitter<any>();

  @Input() idServiceOrder:number;
  
  @Input() modelSearch:FormGroup; 
  @Input() getInfosResponse:GetInfosResponse;
  @Input() year:GetInfosResponse;
  @Input() plateNumber:GetInfosResponse;
  @Input() brandName:string;
  @Input() vehicleModel:string;
  @Input() versionName:string;
  
  clickFilter(){
    this.OutputClickFilter.emit();
  }

  seach(){
    this.SearchClick.emit();
  }

  openModalChengeVehicle(){
    const dialogRef = this.dialog.open(ModalChangeVehicleComponent, {
      data: {
       
      },
    })
    dialogRef.afterClosed().subscribe({
      next: result => {
        
      }
    });
  }

}

