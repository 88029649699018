import { Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule, FormGroup, FormBuilder} from '@angular/forms';
import { MatInputModule} from '@angular/material/input';
import { MatRadioModule} from '@angular/material/radio';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { Location } from '@angular/common';

@Component({
  selector: 'app-business-data',
  templateUrl: './business-data.component.html',
  styleUrls: ['./business-data.component.css']
})
export class BusinessDataComponent implements OnInit {

  constructor(private dialogRef: MatDialog,
    private alertService:AlertService,
    private formBuilder: FormBuilder,
    private location: Location){

      this.model = this.formBuilder.group({
        seachText: [''],
      });

  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public FormFieldModuel: MatFormFieldModule;
  public FormsModule: FormsModule;
  public MatInputModule: MatInputModule;
  public MatRadioModule: MatRadioModule;
  public MatCheckboxModule: MatCheckboxModule;
  public isLoading: boolean;
  public model: FormGroup;
  public token: any;
  public businessName: string;
  public branchName: string;
  public cnpj: number;
  public phone: number;
  public street: number;
  public houseNumber: string;
  public neighborhood: string;

  ngOnInit(){
    this.menuModuleEnum = parseInt(sessionStorage.getItem("module"));
    this.token = JSON.parse(sessionStorage.getItem("token"));
    this.populatePage()
  }

  populatePage(){
    this.token = this.token.branch
    this.businessName = this.token.businessName
    this.branchName = this.token.branchName
    this.cnpj = this.token.cnpj
    this.phone = this.token.phone
    this.street = this.token.street
    this.houseNumber = this.token.houseNumber
    this.neighborhood = this.token.neighborhood

  }

  backPage(){
    this.location.back();
  }


}
