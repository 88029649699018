import { Component, Inject, Output, EventEmitter, HostListener } from '@angular/core';
import { DeliveryAddressComponent } from 'src/app/settings/pages/delivery-address/delivery-address.component';
import { BranchDeliveryAddress } from 'src/app/shared/services/API/models/user/branch-delivery-address.model';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { DeliveryAddressModalComponent } from 'src/app/workshop/pages/purchase/delivery-address-modal/delivery-address-modal.component';
import { BranchDeliveryAdressService } from 'src/app/shared/services/API/services/user/branch-delivery-address.service';
import { BranchDeliveryAdressRequest } from 'src/app/shared/services/API/requests/user/branch-delivery-address.request';
import { BranchDeliveryAdressResponse } from 'src/app/shared/services/API/responses/user/branch-delivery-address.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ListDeliveryAddressService } from 'src/app/shared/services/API/services/user/list-delivery-address.service';
import { BehaviorSubject } from 'rxjs';



@Component({
  selector: 'app-registration-address-modal',
  templateUrl: './registration-address-modal.component.html',
  styleUrls: ['./registration-address-modal.component.css']
})
export class RegistrationAddressModalComponent  {

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private branchDeliveryAdressService: BranchDeliveryAdressService,
    private alertService: AlertService,
    public matDialogRef: MatDialogRef<DeliveryAddressModalComponent>,
    private listDeliveryAddressService: ListDeliveryAddressService,
  ){
    this.addressRequest = new BranchDeliveryAdressRequest();
    this.onResize();
  }
  
  public delivery : DeliveryAddressComponent;
  public listAdress = this.data.listBranchDeliveryAddress;
  public addressRequest: BranchDeliveryAdressRequest;
  public isLoading: boolean = false;
  public idBranchDeliveryAddress: number;
  public token;
  public dataArray;
  public idBranch: number;
  public defaultAddress: BranchDeliveryAddress | null = null;
  public screenWidth: number;
  
  @Output() confirmRegister = new EventEmitter<boolean>();

    @HostListener('window:resize', ['$event'])
    onResize(event?) {
      this.screenWidth = window.innerWidth;
    }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
  }

    openNewAddress(address: BranchDeliveryAddress, isUpdate: boolean = false) {
      const dialogRef = this.dialog.open(DeliveryAddressModalComponent, {
        data: {
          address: address,
          isUpdate
        },
      })
      dialogRef.afterClosed().subscribe((address) => {
          this.confirmRegister.emit(true);
      });
    }

  updateAddress(item: BranchDeliveryAddress) {
    if (!this.addressRequest) {
        console.error("addressRequest não está inicializada!");
        return;
    }
    let isDefault = true
    this.addressRequest.idBranchDeliveryAdress = item.idBranchDeliveryAddress;
    this.addressRequest.latitude = item.latitude;
    this.addressRequest.longitude = item.longitude;
    this.addressRequest.isDefault = isDefault

    this.branchDeliveryAdressService.updateAddress(this.addressRequest).subscribe({
        next: (response: ReturnStruct) => {
            if (response.isError) {
                this.alertService.show("Erro", response.errorDescription, AlertType.error);
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            this.alertService.show("Sucesso", "Endereço atualizado com sucesso", AlertType.success);
            this.matDialogRef.close();
        },
        error: (error) => {
            this.alertService.show("Ação não concluída.", error, AlertType.error);
            this.isLoading = false;
        }
    });
}

}
