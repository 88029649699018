<div class="white-body">
  <div class="header" *ngIf="screenWidth >= 650">
    <div>
      <h2>Endereços de Entrega</h2>
    </div>
    <div>
      <div class="buttonUser">
        <button mat-button color="primary" id="add-cart" (click)="openNewAddress(null)" type="button">
          <mat-icon class="material-symbols-outlined icon-add">add</mat-icon>
          Cadastrar Novo Endereço
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="screenWidth < 650">
    <div>
        <h2>Endereços de Entrega</h2>
    </div>
    <div>
      <div class="buttonUser">
        <button mat-button color="primary" id="add-cart" (click)="openNewAddress(null)" type="button">
          <mat-icon class="material-symbols-outlined icon-add">add</mat-icon>
          Cadastrar Novo Endereço
        </button>
      </div>
    </div>
  </div>
  <div class="row table-responsive" *ngIf="screenWidth >= 650">
    <table class="basic-table table-color">
      <thead class="basic-table__head">
        <tr class="truncate">
          <th>Nome</th>
          <th>Endereço</th>
          <th>Editar</th>
          <th></th>
        </tr>
      </thead>
      <tbody class="basic-table__body" *ngFor="let item of listAdress | keyvalue">
          <tr 
            class="basic-table__body__row teste"
            [ngClass]="{ 'disabled-row': item.value.isDefault }">
            <ng-container (click)="item.value.isDefault ? null : updateAddress(item.value)">
              <td class="truncate">{{ item.value.addressName }}</td>
              <td class="truncate">
                {{ item.value.street }}, {{ item.value.number }} - {{ item.value.neighborhood }} - {{ item.value.city }}
              </td>
            </ng-container>
            <td class="truncate">
              <button 
                mat-icon-button 
                aria-label="Example icon button with a home icon"
                (click)="openNewAddress(item.value)">
                <mat-icon>launch</mat-icon>
              </button>
            </td>
          </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="screenWidth < 650">
    <div class="row card-spaccing" *ngFor="let item of listAdress | keyvalue">
      <div class="col-sm-6 w-100">
        <div class="card">
          <div class="card-body">
            <div class="row card-title">
              <ul class="ul">
                <li class="title">
                  <span class="second-title">Nome: </span>
                  <span>{{item.value.addressName}}</span>
                </li>
              </ul>
            </div>
            <div class="row">
              <ul>
                <li class="title">
                  <span class="second-title">Endereço: </span>

                  <span>{{item.value.street}}, {{item.value.number}} - {{item.value.neighborhood}} - {{item.value.city}}</span>

                </li>
              </ul>
            </div>
            <div class="row button-card">
              <div class="button-os">
                <div class="button" mat-dialog-actions>
                  <button style="background-color: #001F38; color: white;" id="button-mobile" mat-button type="button"
                    class="btn-primary" color="primary" (click)="openNewAddress(item.value)">
                    Editar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
