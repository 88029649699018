<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>


<div class="container-body">
  <div class="settings">
    <h2>Configurações</h2>
    <hr>
  </div>
  <div class="row">
    <a routerLink="/user" class="white-settings col-md-4">
      <div class="img">
        <img src="assets/images/settings/usuarios.svg">
      </div>
      <h3>Usuários</h3>
    </a>
    <a routerLink="/profile-permissions" class="white-settings col-md-4">
      <div class="img">
        <img src="assets/images/settings/perfis.svg">
      </div>
      <h3>Perfis e Permissões</h3>
    </a>
    <a routerLink="/business-data" class="white-settings col-md-4">
      <div class="img">
        <img src="assets/images/settings/dados.svg">
      </div>
      <h3>Dados da Oficina & Filiais</h3>
    </a>
    <a routerLink="/payment" class="white-settings col-md-4">
      <div class="img">
        <img src="assets/images/settings/pagamento.svg">
      </div>
      <h3>Formas de Pagamento</h3>
    </a>
    <a routerLink="/delivery-address" class="white-settings col-md-4">
      <div class="img">
        <img src="assets/images/settings/pagamento.svg">
      </div>
      <h3>Endereços de Entrega</h3>
    </a>
    <!-- <a routerLink="/Welcome" class="white-settings col-md-4">
      <div class="img">
        <img src="assets/images/settings/marcas.svg">
      </div>
      <h3>Marcas</h3>
    </a>
    <a routerLink="/Welcome" class="white-settings col-md-4">
      <div class="img">
        <img src="assets/images/settings/markup.svg">
      </div>
      <h3>Markup</h3>
    </a> -->
  </div>
</div>
