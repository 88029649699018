import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ApplicabilityInfoStruct as ApplicabilityInfoStruct } from 'src/app/shared/services/API/structs/autoparts/applicability-info.struct';
import {PartProductStruct} from 'src/app/shared/services/API/structs/autoparts/part-product.struct';
import { AlertService,AlertType } from 'src/app/shared/services/alert.service';

@Component({
  selector: 'app-search-list-item',
  templateUrl: './search-list-item.component.html',
  styleUrls: ['./search-list-item.component.css']
})
export class SearchListItemComponent implements OnInit {

  constructor(private formBuilder: FormBuilder,
    private alertService: AlertService,
  ) {

  }

  public model: FormGroup;
  @Input() partProductStruct: PartProductStruct;
  // @Input() applicabilitInfo: ApplicabilityInfoStruct;
  @Output() addItem = new EventEmitter<number>();
  public maxAmount: number;
  public salesMultiple: number;
  public quantity: any;
  public collectedParts: PartProductStruct[] = [];
  public filteredParts: PartProductStruct[] = [];

  ngOnInit(): void {

    this.salesMultiple = this.partProductStruct.salesMultiple;

      this.model = this.formBuilder.group({
        amount: this.salesMultiple,
      });

    this.quantity = this.partProductStruct.totalQuantity;
  }

  applyFilter(item: any) {
    if (item.length > 0) {
      this.filteredParts = this.collectedParts.filter(part => item.includes(part.brandName));
    } else {
      this.filteredParts = [...this.collectedParts]; // Se nenhum filtro estiver selecionado, exibe todos os itens
    }
  }

  generateAmountOptions(): number[] {
    return Array.from(
      { length: Math.ceil(this.quantity / this.salesMultiple) }, 
      (_, i) => (i + 1) * this.salesMultiple
    );
  }

  clickAddItem() {
    const amount = this.model.get("amount").value;
    this.addItem.emit(amount);
    if (amount) {
      this.alertService.show('Sucesso', 'Item adicionado ao carrinho.', AlertType.success);
    }
  }
}
