<div [formGroup]="formGroup">
    <div class="row label-row">   
        <div class="col-md-12 login-label" (click)="selectLogin()">
            <mat-icon class="vertical-align" aria-hidden="false" aria-label="Plus">account_circle</mat-icon>{{email}}
        </div>
    </div>

    <div class="row" *ngIf="listBranch && listBranch.length>1">   
        <div class="col-md-12">
            <mat-form-field appearance="outline">
                <mat-label>Selecione o local de entrada</mat-label>
                <mat-select formControlName="idBranch" (selectionChange)="onIdBranchChange()">                       
                    <mat-option *ngFor="let item of listBranch" [value]="item.idBranch">
                        {{item.businessName}} | {{item.branchName}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('idBranch').invalid">Informe a Loja</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row" *ngIf="listBranch.length<1 || formGroup.get('idBranch').valid">
        <div class="col-md-12">
            <mat-form-field appearance="outline">
                <mat-label>Senha</mat-label>
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
                <input matInput #password type="password" [type]="hide ? 'text' : 'password'" formControlName="password" required>
                <mat-error *ngIf="formGroup.get('password').invalid">Informe a senha</mat-error>
            </mat-form-field>
        </div>
        <div class="forgot-password-link">
            <a routerLink="/forgot-password">
                Esqueceu sua senha?
            </a> 
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 align-center" *ngIf="listBranch && listBranch.length > 0">
            <button mat-flat-button type="submit" color="primary" class="btn-primary btn-block">
                <span *ngIf="isLoading == false">Entrar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
        </div>
    </div>
</div>
