<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<div class="container-body">

  <div class="col-md-3">
    <button class="button-back" (click)="backPage()">
      <mat-icon class="icon-back">keyboard_arrow_left</mat-icon>
      <span>Voltar</span>
    </button>
  </div>
  <div class="row">
    <h2>Dados da Oficina & Filiais</h2>
    <div class="white-body ">
      <div class="row header table-responsive" [formGroup]="model">
        <div class="col-md-4 title">
          <h2>Dados da Marca</h2>
        </div>
        <div class="table">
          <ul class="ul-format">
            <div class="col-sm-12 text">
              <li class="li-format">Nome
              </li>
              <span>{{this.businessName}}</span>
            </div>
            <hr>
            <div class="col-sm-12 text">
              <li class="li-format">Telefone
              </li>
              <span>{{this.phone}}</span>
            </div>
            <hr>
          </ul>
          <ul class="ul-format">
            <div class="col-sm-12 text">
              <li class="li-format">CNPJ
              </li>
              <span>{{this.cnpj}}</span>
            </div>
            <hr>
            <div class="col-sm-12 text">
              <li class="li-format">Loja Matriz
              </li>
              <span>{{this.branchName}}</span>
            </div>
            <hr>
          </ul>
          <ul class="ul-format">
            <div class="col-sm-12 text">
              <li class="li-format">Responsável
              </li>
              <span></span>
            </div>
            <hr>
            <div class="col-sm-12 text">
              <li class="li-format">Logo
              </li>
              <button mat-flat-button color="primary">Update/Trocar</button>
            </div>
            <hr>
          </ul>
        </div>
      </div>
      <div class="row table-responsive">
        <table class="basic-table">
          <thead class="basic-table__head">
            <tr class="truncate">
              <th>Nome</th>
              <th>Unidade</th>
              <th>Endereço</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="basic-table__body">
            <tr class="basic-table__body__row">
              <td class="truncate">{{this.businessName}}</td>
              <td class="truncate">{{this.branchName}}</td>
              <td class="truncate">{{this.street}}, {{this.houseNumber}} - {{this.neighborhood}}</td>
              <td class="truncate">
                  <!-- <a href="">
                      <mat-icon style="color: #001F38;">arrow_circle_right</mat-icon>
                  </a> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
