import { Component, HostListener, OnInit } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { BranchDeliveryAddress } from 'src/app/shared/services/API/models/user/branch-delivery-address.model';
import { PopulatePurchaseService } from 'src/app/shared/services/API/services/orchestrator-purchase/populate-purchase.service';
import { GetPurchaseDataResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/get-purchase-data.response';
import { SupplierBranchStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/supplier-branch.struct';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PurchaseRequest } from 'src/app/shared/services/API/requests/orchestrator-purchase/purchase.request';
import { ListDeliveryAddressService } from 'src/app/shared/services/API/services/user/list-delivery-address.service';
import { ListDeliveryAdressRequest } from 'src/app/shared/services/API/requests/user/list-delivery-address.request';
import { BranchDeliveryAdressResponse } from 'src/app/shared/services/API/responses/user/branch-delivery-address.response';
import { MatDialog } from '@angular/material/dialog';
import { DeliveryAddressModalComponent } from 'src/app/workshop/pages/purchase/delivery-address-modal/delivery-address-modal.component';
import { Location } from '@angular/common';
import { ModalAddressDeleteComponent } from '../delivery-address/modal-address-delete/modal-address-delete.component';
import { BranchDeliveryAdressService } from 'src/app/shared/services/API/services/user/branch-delivery-address.service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';

@Component({
  selector: 'app-pick-up-address',
  templateUrl: './pick-up-address.component.html',
  styleUrls: ['./pick-up-address.component.css']
})
export class PickUpAddressComponent {

  constructor(private listDeliveryAddressService: ListDeliveryAddressService,
    private alertService:AlertService,
    private branchDeliveryAdressService:BranchDeliveryAdressService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private location: Location,
    private dialogRef: MatDialog,
  ){

    this.model = this.formBuilder.group({
      idBranchAddress: ['', [Validators.required]],
    });
    this.onResize();
    
  }
  
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.fornecedor;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.fornecedor_pedido;
  public branchDeliveryAdressResponse: BranchDeliveryAdressResponse;
  public listSupplierBranchStruct: SupplierBranchStruct[];
  public listDelivery: []= [];
  public listBranchDeliveryAddress: BranchDeliveryAddress[] = [];
  public isLoading: boolean;
  public model: FormGroup = null;
  public screenWidth: number;
  public token;
  public idBranch: number;
  public dataArray;


  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void{

    this.populatePage()
  }


  populatePage(){
    const response = sessionStorage.getItem("token");
    this.token = response;
    this.dataArray = JSON.parse(response);
    this.idBranch = this.dataArray.listBranch[0].idBranch;
    let request = [this.idBranch];

    this.listDeliveryAddressService.post(request).subscribe({
      next: (response: BranchDeliveryAdressResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          this.isLoading = false;
          return;
        }
        this.branchDeliveryAdressResponse = response;
        
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  backPage(){
    this.location.back();
  }

  openDialogDelete(item: any): void{
    const dialogRef = this.dialogRef.open(ModalAddressDeleteComponent)
    const idBranchDeliveryAddress: number = item.idBranchDeliveryAddress;

    dialogRef.componentInstance.deleteConfirm.subscribe(() => {
      this.branchDeliveryAdressService.delete(idBranchDeliveryAddress).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct && returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return;
          }
          this.populatePage();
          if (this.alertService) {
            this.alertService.show('Excluído', 'Usuário excluído', AlertType.success);
            this.dialogRef.closeAll();
          }
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Erro inesperado', error, AlertType.error);
          }
          this.isLoading = false;
        }
      });
    });
  }

  openAddressModal(address: BranchDeliveryAddress, isUpdate: boolean = false) {
    const dialogRef = this.dialog.open(DeliveryAddressModalComponent, {
      data: {
        address: address,
        isUpdate
      },
    })
    dialogRef.componentInstance.confirmRegister.subscribe({
      next: result => {
        if (result && result.address) {
          this.listBranchDeliveryAddress.push(result.address)
        }
        this.populatePage()
      }
    });
  }

}
