<div class="header-search"  [ngClass]="{'header-search-big-top': idServiceOrder != undefined}">
    <div class="header-search-inner">
        <div class="car-info" *ngIf="getInfosResponse != null && getInfosResponse.brand != null">
            <h4>Comprando para</h4>
            <p>{{plateNumber}}</p>
            <p>{{getInfosResponse.brand}}</p>
            <p>{{getInfosResponse.vehicleModel}}</p>
            <p>{{getInfosResponse.modelYear}}</p>
            <p>{{getInfosResponse.version}}</p>
        </div>
        <div class="car-info" *ngIf="brandName != null && brandName != null">
            <h4>Comprando para</h4>
            <p>{{brandName}}</p>
            <p>{{vehicleModel}}</p>
            <p>{{year}}</p>
            <p>{{versionName}}</p>
        </div>
        <a *ngIf="getInfosResponse != null || brandName != null" mat-flat-button color="accent" class="btn" routerLink="/workshop/welcome">
            <mat-icon class="material-symbols-outlined">cached</mat-icon> 
            <span class="btn-text">Trocar veículo</span>
        </a>

        <!-- <a color="accent" class="btn-xs" routerLink="/workshop/welcome">
            <mat-icon class="material-symbols-outlined">cached</mat-icon> 
        </a> -->

        <!-- <a  color="accent" class="btn filter-btn" (click)="clickFilter()">
            <mat-icon class="material-symbols-outlined">filter_alt_off</mat-icon> 
        </a>
        -->
        <div class="search-inputInfo" *ngIf="getInfosResponse == null">
            <form class="form" [formGroup]="modelSearch">
                <input type="text" formControlName="description">
                <button (click)="seach()">
                    <div class="vertical-aling-out">
                        <div class="vertical-aling-inner">
                            <mat-icon class="material-symbols-outlined">search</mat-icon>
                        </div>
                    </div>
                </button>
            </form>
        </div>
        <div *ngIf="getInfosResponse != null" class="search-input">
            <form class="form" [formGroup]="modelSearch">
                <input type="text" formControlName="description">
                <button (click)="seach()">
                    <div class="vertical-aling-out">
                        <div class="vertical-aling-inner">
                            <mat-icon class="material-symbols-outlined">search</mat-icon>
                        </div>
                    </div>
                </button>
            </form>
        </div>
    </div>
    
    <div class="car-info-xs" *ngIf="getInfosResponse != null && getInfosResponse.brand != null">
        <p>{{getInfosResponse.modelYear}}</p>
        <p>{{getInfosResponse.version}}</p>
    </div>
    <hr class="hrGetInfo" *ngIf="getInfosResponse != null|| brandName != null">
    <hr class="hrGetInfoNull" *ngIf="getInfosResponse == null && brandName == null">
</div> 